<script lang="ts">
	import { classes } from '$lib/utils';

	export let additionalClass: string = '';
	// Provide value as a hex color code.
	export let color: string | undefined = '#e5e5e5';
	// Unit is px.
	export let length: string | undefined = '400px';
	// Unit is px.
	export let width: string | undefined = '2px';
	export let orientation: 'horizontal' | 'vertical' = 'horizontal';
</script>

<div
	id="divider-line"
	class={classes(additionalClass, `${orientation}`)}
	style="--divider-width: {width}; --divider-length: {length}; --divider-color: {color}"
/>

<style>
	#divider-line {
		display: block;
	}

	#divider-line.horizontal {
		width: var(--divider-length, 100%);
		height: 0;
		border-top: var(--divider-width, 2px) solid var(--divider-color, #e5e5e5);
	}

	#divider-line.vertical {
		width: 0;
		height: var(--divider-length, 100%);
		border-left: var(--divider-width, 2px) solid var(--divider-color, #e5e5e5);
	}
</style>
